import { createRouter, createWebHistory, RouteRecordRaw } from 'vue-router'

const routes: Array<RouteRecordRaw> = [
  {
    path: '/',
    redirect: "/index",
  },
  {
    path: '/home',
    redirect: "/index",
  },
  {
    path: '/index',
    name: 'index',
    component: () => import("@/views/homeView.vue"),
    meta: {
      title: '首页',
    }
  },
  //期次详情
  {
    path: '/periodDetail',
    name: 'periodDetail',
    component: () => import("@/views/periodDetail.vue"),
    meta: {
      title: '期次详情',
    }
  },
  //在线报名
  {
    path: '/apply',
    name: 'apply',
    component: () => import("@/views/apply.vue"),
    meta: {
      title: '在线报名',
    }
  },
  //结果查询
  {
    path: '/resultsQuery',
    name: 'resultsQuery',
    component: () => import("@/views/resultsQuery.vue"),
    meta: {
      title: '报名结果查询',
    }
  },
  //扫码就餐
  {
    path: '/dining',
    name: 'dining',
    component: () => import("@/views/dining.vue"),
    meta: {
      title: '扫码就餐',
    }
  },
  //就餐码领取
  {
    path: '/groupReceive',
    name: 'groupReceive',
    component: () => import("@/views/groupReceive.vue"),
    meta: {
      title: '就餐码领取',
    }
  },
  //管理员登录
  {
    path: '/login',
    name: 'login',
    component: () => import("@/views/login.vue"),
    meta: {
      title: '系统登录',
    }
  },
  //管理员首页
  {
    path: '/manageIndex',
    name: 'manageIndex',
    component: () => import("@/views/manageIndex.vue"),
    meta: {
      title: '管理员首页',
    }
  },
  //管理员期次列表
  {
    path: '/managePeriodDetail',
    name: 'managePeriodDetail',
    component: () => import("@/views/managePeriodDetail.vue"),
    meta: {
      title: '管理员期次列表',
    }
  },

]

const router = createRouter({
  history: createWebHistory(process.env.BASE_URL),
  routes
})

export default router
